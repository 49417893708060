<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <h3>گزارش ساعات فعالیت</h3>
      </v-card-title>
      <v-card class="cards pa-3">
        <v-card-text>
          <div>
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateFrom">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateFrom"
                    label=" تاریخ از "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateFrom"
                  element="dateFrom"
                  color="#00a7b7"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateTo">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateTo"
                    label=" تاریخ تا "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateTo"
                  element="dateTo"
                  color="#00a7b7"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  class="submit-btn primary-btn"
                  style="float: right"
                  @click="activityHours()"
                  :disabled="Busy"
                  >اعمال</v-btn
                >
              </v-col>
              <v-col>
                <v-btn
                  @click="$refs.activityExcel.$el.click()"
                  style="float: left"
                  class="mb-3 secondary-btn"
                  :disabled="
                    !Items.doctors &&
                    !Items.nurses &&
                    !Items.receptions &&
                    !Items.servants
                  "
                  >دریافت اکسل گزارش</v-btn
                >
                <vue-excel-xlsx
                  v-show="false"
                  ref="activityExcel"
                  :data="excelItems"
                  :columns="
                    excelFields.map((x) => {
                      return {
                        label: x.label,
                        field: x.key,
                      };
                    })
                  "
                  :filename="
                    'گزارش ساعات فعالیت از ' + dateFrom + ' تا ' + dateTo
                  "
                  :sheetname="currentDate"
                >
                  دریافت اکسل
                </vue-excel-xlsx>
              </v-col>
            </v-row>
            <hr />
            <!--<v-row align-v="end">
              <v-col></v-col>
              <v-col>
                <v-btn
                  @click="$refs.activityExcel.$el.click()"
                  style="float: left"
                  class="mb-3 secondary-btn"
                  >دریافت اکسل</v-btn
                >
                <vue-excel-xlsx
                  v-show="false"
                  ref="activityExcel"
                  :data="excelItems"
                  :columns="
                    excelFields.map(x => {
                      return {
                        label: x.label,
                        field: x.key
                      };
                    })
                  "
                  :filename="
                    'گزارش ساعات فعالیت از ' + dateFrom + ' تا ' + dateTo
                  "
                  :sheetname="currentDate"
                >
                  دریافت اکسل
                </vue-excel-xlsx>
              </v-col>
            </v-row>-->
            <v-tabs
              class="mb-2"
              fixed-tabs
              background-color="transparent"
              v-model="tab"
            >
              <v-tab class="tab-title"> پزشک </v-tab>
              <v-tab class="tab-title"> پرستار و پیراپزشک </v-tab>
              <v-tab class="tab-title"> کارمندان پذیرش </v-tab>
              <v-tab class="tab-title"> کارکنان خدمات </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <!-- پزشک -->
              <v-tab-item>
                <v-card>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="Items.doctors"
                    empty-text="در بازه زمانی انتخاب شده فعالیتی برای نمایش وجود ندارد"
                    empty-filtered-text="در بازه زمانی انتخاب شده فعالیتی برای نمایش وجود ندارد"
                    :busy="Busy"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.value }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                    <div
                      slot="table-busy"
                      class="text-center primary--text my-2"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="align-middle"
                      ></v-progress-circular>
                    </div>
                  </b-table>
                </v-card>
              </v-tab-item>
              <!-- پرستار و پیراپزشک -->
              <v-tab-item>
                <v-card>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="Items.nurses"
                    empty-text="در بازه زمانی انتخاب شده فعالیتی برای نمایش وجود ندارد"
                    empty-filtered-text="در بازه زمانی انتخاب شده فعالیتی برای نمایش وجود ندارد"
                    :busy="Busy"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.value }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                    <div
                      slot="table-busy"
                      class="text-center primary--text my-2"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="align-middle"
                      ></v-progress-circular>
                    </div>
                  </b-table>
                </v-card>
              </v-tab-item>
              <!-- کارمندان پذیرش -->
              <v-tab-item>
                <v-card>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="Items.receptions"
                    empty-text="در بازه زمانی انتخاب شده فعالیتی برای نمایش وجود ندارد"
                    empty-filtered-text="در بازه زمانی انتخاب شده فعالیتی برای نمایش وجود ندارد"
                    :busy="Busy"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.value }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                    <div
                      slot="table-busy"
                      class="text-center primary--text my-2"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="align-middle"
                      ></v-progress-circular>
                    </div>
                  </b-table>
                </v-card>
              </v-tab-item>
              <!-- کارکنان خدمات -->
              <v-tab-item>
                <v-card>
                  <b-table
                    responsive
                    show-empty
                    :fields="Fields"
                    :items="Items.servants"
                    empty-text="در بازه زمانی انتخاب شده فعالیتی برای نمایش وجود ندارد"
                    empty-filtered-text="در بازه زمانی انتخاب شده فعالیتی برای نمایش وجود ندارد"
                    :busy="Busy"
                  >
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.value }}
                      </div>
                    </template>
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                    <div
                      slot="table-busy"
                      class="text-center primary--text my-2"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="align-middle"
                      ></v-progress-circular>
                    </div>
                  </b-table>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      tab: null,
      TotalRows: "",
      Busy: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام" },
        { key: "time", label: "ساعت کارکرد" },
      ],
      agFields: [
        { field: "index", headerName: "#" },
        { field: "name", headerName: "نام" },
        { field: "time", headerName: "ساعت کارکرد" },
      ],
      excelFields: [
        { key: "name", label: "نام" },
        { key: "time", label: "ساعت کارکرد" },
      ],
      Items: [],
      excelItems: [],
      dateFrom: moment(Date.now() - 86400000).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: moment(new Date()).format("HH:mm"),
      timeTo: moment(new Date()).format("HH:mm"),
      busyDocPercent: false,
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
    };
  },

  methods: {
    activityHours() {
      //NOTE showing all services info services
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/workHours",
          {
            startDate: this.dateFrom,
            endDate: this.dateTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data;
            if (this.Items.length > 0) {
              this.excelItems = this.Items.doctors.concat(
                this.Items.nurses,
                this.Items.receptions,
                this.Items.servants
              );
              this.excelItems.push({
                name: "مجموع",
                time: this.Items.totalTime,
              });
              this.Items.doctors.push({
                name: "مجموع",
                time: this.Items.docTotal,
                _rowVariant: "info",
              });
              this.Items.nurses.push({
                name: "مجموع",
                time: this.Items.nurseTotal,
                _rowVariant: "info",
              });
              this.Items.receptions.push({
                name: "مجموع",
                time: this.Items.receptionTotal,
                _rowVariant: "info",
              });
              this.Items.servants.push({
                name: "مجموع",
                time: this.Items.servantTotal,
                _rowVariant: "info",
              });
            }
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.busyDocPercent = false;
          this.Busy = false;
        });
    },
  },

  mounted() {
    this.activityHours();
  },
};
</script>
